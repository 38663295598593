import { Box, Container, Grid, Paper } from "@mui/material";
import DocVisualCrossVisualTable from "./DocVisualCrossVisualTable";
import DocVisualCrossVisualCompareTable from "./DocVisualCrossVisualCompareTable";
import DocVisualCrossVisualMeta from "./DocVisualCrossVisualMeta";
// import DocVisualCrossVisualOpinion from "./DocVisualCrossVisualOpinion";

export default function DocVisualCrossVisual() {
  return (
    <Box sx={{ my: 3 }}>
      <Container maxWidth="false">
        <DocVisualCrossVisualMeta />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            {/* <Paper elevation={6} sx={{ height: "100%", position: "relative" }}> */}
            <DocVisualCrossVisualTable />
            {/* </Paper> */}
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{ height: "calc(100vh - 190px)" }}
          >
            <Paper
              elevation={6}
              sx={{ mb: 3, height: "100%", position: "relative" }}
            >
              <DocVisualCrossVisualCompareTable />
            </Paper>

            {/* <Paper elevation={6} sx={{ height: '100%', position: 'relative' }}>
              <DocVisualCrossVisualOpinion/>
            </Paper> */}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
