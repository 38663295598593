import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import React, { useState } from "react";
import CloudListAPI from "../../services/cloud/CloudListAPI";
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPath } from "../../actions";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const SheetCloudFile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectCheckbox, setSelectCheckbox] = useState([]);
  const [filePath, setFilePath] = useState([]);
  const [visualSheetAlert, setVisualSheetAlert] = useState(false);

  const currentPath = useSelector((state) => state.currentPath);
  const processedPath = currentPath.path.replace(/\/[^/]+\/?$/, "/");
  const pathSegments = currentPath.path
    .split("/")
    .filter((segment) => segment !== "");
  const fileList = useSelector((state) => state.fileList);

  const handleBreadCrumb = (key) => {
    if (key === 0) {
      dispatch(setCurrentPath(processedPath));
    } else if (key >= 1) {
      const newPath = processedPath + pathSegments[key] + "/";
      dispatch(setCurrentPath(newPath));
    }
  };

  const handleDoubleClick = (param) => {
    const doubleClickFolder = param.row.extension;
    if (doubleClickFolder === "폴더") {
      dispatch(setCurrentPath(param.row.filePath));
    }
  };

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#F5F5F5",
            },
          },
        },
      },
    },
  });

  const rows = fileList
    .filter((file) => ["png", "폴더"].includes(file.extension.toLowerCase()))
    .map((file, index) => ({
      id: index,
      fileName: file.fileName,
      extension: file.extension,
      fileSize: file.fileSize,
      registeredAt: file.registeredAt,
      filePath: file.filePath,
    }));

  const columns = [
    {
      field: "fileName",
      headerName: "이름",
      width: 300,
      renderCell: (params) => {
        const isFolder = params.row.extension === "폴더";
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {isFolder ? (
              <FolderIcon style={{ marginRight: "10px", color: "#666666" }} />
            ) : (
              <InsertDriveFileOutlinedIcon style={{ marginRight: "10px" }} />
            )}
            {params.value}
          </div>
        );
      },
    },
    { field: "registeredAt", headerName: "생성 날짜", width: 300 },
    { field: "fileSize", headerName: "파일 크기", width: 150 },
  ];

  const handleSelectCheckbox = (newSelectionModel, rows) => {
    if (newSelectionModel.length > 1) {
      alert("1개 파일만 선택해 주세요");
      setSelectCheckbox(newSelectionModel.slice(0, 1));
      return;
    }
    setSelectCheckbox(newSelectionModel);

    const filePath = newSelectionModel.map((id) => rows[id].filePath);
    setFilePath(filePath[0]);
  };

  const handleSheetVisual = () => {
    if (filePath.includes(".png")) {
      // .png가 포함된 경우 실행
      const fileName = filePath.split("/").slice(1).join("/");
      navigate("/module/sheetvisual/sheet", { state: { fileName } });
      window.location.reload();
    } else {
      // .png가 포함되지 않은 경우 경고 알림 상태 변경
      setVisualSheetAlert(true);
    }
  };

  return (
    <>
      <CloudListAPI />

      <Box
        sx={{
          py: 1,
          pl: 2,
          display: "flex",
          justifyContent: "space-between",
          overflowX: "auto",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ whiteSpace: "nowrap" }}>클라우드 경로 :</Typography>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            sx={{ pl: 2, whiteSpace: "nowrap", minWidth: 200 }}
          >
            {pathSegments.map((segment, index) => (
              <Box
                sx={{
                  "&:hover": { cursor: "pointer" },
                  display: "flex",
                  alignItems: "center",
                }}
                key={index}
                onClick={() => handleBreadCrumb(index)}
              >
                <FolderIcon sx={{ fontSize: "small", mr: 0.5 }} />
                {segment}
              </Box>
            ))}
          </Breadcrumbs>
        </Box>
        <Button
          size="small"
          variant="contained"
          endIcon={<SendIcon />}
          sx={{ px: 2, mr: 2, whiteSpace: "nowrap" }}
          onClick={handleSheetVisual}
        >
          시각화
        </Button>
      </Box>

      <ThemeProvider theme={theme}>
        <DataGrid
          sx={{
            height: "calc(100vh - 220px)",
            "&:hover": { cursor: "default" },
          }}
          rows={rows}
          columns={columns}
          checkboxSelection
          disableColumnMenu
          disableAutoFocus
          disableVirtualization
          disableRowSelectionOnClick
          hideFooter
          onCellDoubleClick={handleDoubleClick}
          onRowSelectionModelChange={(newSelectionModel) =>
            handleSelectCheckbox(newSelectionModel, rows)
          }
          rowSelectionModel={selectCheckbox}
        />
      </ThemeProvider>

      <Dialog
        open={visualSheetAlert}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setVisualSheetAlert(false);
          }
        }}
      >
        <DialogTitle>시각화 오류</DialogTitle>
        <DialogContent>
          <DialogContentText>
            png 파일을 선택해주세요. 지속될 경우 문의 바랍니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVisualSheetAlert(false)}>닫기</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SheetCloudFile;
