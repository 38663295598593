import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setUserLoginAuth,
  setUserNameInfo,
  setUserVolume,
} from "../../actions";
import UserAPI from "../user/UserAPI";

export default function UserLoginCheckAPI() {
  const dispatch = useDispatch();
  const { mypageVolumeInfo } = UserAPI({});

  useEffect(() => {
    const loginAuth = async () => {
      try {
        const response = await axios.get("/isLogin");
        if (response.status === 200) {
          try {
            const response = await axios.get("/api/v1/user/my-info");
            const data = await mypageVolumeInfo();
            const userName = response.data.result.name;
            dispatch(setUserNameInfo(userName));
            dispatch(setUserVolume(data));
          } catch (error) {
            console.error(error);
          }
          dispatch(setUserLoginAuth(true));
          window.location.href = `/module`;
        }
      } catch (error) {
        if (error.response && error.response.status !== 200) {
          dispatch(setUserLoginAuth(false));
        }
      }
    };
    loginAuth();
    //eslint-disable-next-line
  }, []);
}
