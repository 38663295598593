import HandleValidationHook from "../../hooks/HandleValidationHook.jsx";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setChangePhoneAlert,
  setDeactivateUserAlert,
  setCompleteDeactivateUserAlert,
  setChangePwAlert,
  setCompleteChangePwAlert,
  setAdministratorPage,
} from "../../actions/index.js";
import UserAPI from "../../services/user/UserAPI.jsx";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CakeIcon from "@mui/icons-material/Cake";
import Man4Icon from "@mui/icons-material/Man4";
import ApartmentIcon from "@mui/icons-material/Apartment";
import WorkIcon from "@mui/icons-material/Work";
import LockIcon from "@mui/icons-material/Lock";
import ButtonStatusHook from "../../hooks/ButtonStatusHook.jsx";
import { useNavigate } from "react-router-dom";
import AdminListAPI from "../../services/admin/AdminListAPI.jsx";
import MyPageGradeInfo from "./MyPageGradeInfo.jsx";
import { useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { blue, green, grey, red } from "@mui/material/colors";
import { ResponsiveBar } from "@nivo/bar";

export default function MyPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.userInfo);
  const userVolume = useSelector((state) => state.userVolume);
  const changePhoneError = useSelector((state) => state.changePhoneError);
  const changePhoneButtonStatus = useSelector(
    (state) => state.changePhoneButtonStatus
  );
  const changePhoneAlert = useSelector((state) => state.changePhoneAlert);
  const changePhoneResult = useSelector((state) => state.changePhoneResult);
  const changeCurrentPwError = useSelector(
    (state) => state.changeCurrentPwError
  );
  const changePwError = useSelector((state) => state.changePwError);
  const changePwConfirmError = useSelector(
    (state) => state.changePwConfirmError
  );
  const changePwAlert = useSelector((state) => state.changePwAlert);
  const completeChangePwAlert = useSelector(
    (state) => state.completeChangePwAlert
  );
  const completeChangePwResult = useSelector(
    (state) => state.completeChangePwResult
  );
  const changePwButtonStatus = useSelector(
    (state) => state.changePwButtonStatus
  );
  const deactivateUserAlert = useSelector((state) => state.deactivateUserAlert);
  const completeDeactivateUserAlert = useSelector(
    (state) => state.completeDeactivateUserAlert
  );
  const completeDeactivateUserResult = useSelector(
    (state) => state.completeDeactivateUserResult
  );

  const {
    handleChangePhoneValidation,
    handleChangeCurrentPwValidation,
    handleChangePwValidation,
    handleChangePwConfirm,
  } = HandleValidationHook({});

  const {
    onClickChangePhoneButton,
    onClickChangePwButton,
    onClickDeactivateUser,
  } = UserAPI({});
  const { administratorInfo } = AdminListAPI({});

  const userInfoArray = [
    { label: "이름", value: userInfo && userInfo.name ? userInfo.name : null },
    {
      label: <EmailIcon />,
      value: userInfo && userInfo.username ? userInfo.username : null,
    },
    {
      label: <CakeIcon />,
      value:
        userInfo && userInfo.birth
          ? userInfo.birth?.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3")
          : null,
    },
    {
      label: <Man4Icon />,
      value:
        userInfo && userInfo.gender
          ? userInfo.gender === "M"
            ? "남성"
            : userInfo.gender === "F"
            ? "여성"
            : null
          : null,
    },
    {
      label: <LocalPhoneIcon />,
      value:
        userInfo && userInfo.phone
          ? userInfo.phone?.replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
              "$1-$2-$3"
            )
          : null,
    },
    {
      label: <ApartmentIcon />,
      value: userInfo && userInfo.organization ? userInfo.organization : null,
    },
    {
      label: <WorkIcon />,
      value: userInfo && userInfo.job ? userInfo.job : null,
    },
  ];

  const { mypageUserInfo, mypageVolumeInfo } = UserAPI({});

  useEffect(() => {
    mypageUserInfo();
    mypageVolumeInfo();
    //eslint-disable-next-line
  }, []);

  const storageInfo = [
    {
      used: userVolume?.draw?.originalVolume || 0,
      total: userVolume?.draw?.originalUserVolume || 0,
      usedMB: userVolume?.draw?.volume || 0,
      totalMB: userVolume?.draw?.userVolume || 0,
    }, // draw
    {
      used: userVolume?.sheet?.originalVolume || 0,
      total: userVolume?.sheet?.originalUserVolume || 0,
      usedMB: userVolume?.sheet?.volume || 0,
      totalMB: userVolume?.sheet?.userVolume || 0,
    }, // sheet
    {
      used: userVolume?.doc?.originalVolume || 0,
      total: userVolume?.doc?.originalUserVolume || 0,
      usedMB: userVolume?.doc?.volume || 0,
      totalMB: userVolume?.doc?.userVolume || 0,
    }, // doc
  ];

  const docInfo = [
    {
      used: userVolume?.parserCount?.parsingCount || 0,
      total: userVolume?.parserCount?.userParsingCount || 0,
    }, // parsing
    {
      used: userVolume?.analysisCount?.analysisCount || 0,
      total: userVolume?.analysisCount?.userAnalysisCount || 0,
    }, // analysis
  ];

  // 각각의 chartData 배열 생성
  const parsingChartData = [
    {
      category: "Parsing",
      used: docInfo[0].used,
      total: docInfo[0].total,
    },
  ];

  const analysisChartData = [
    {
      category: "Analysis",
      used: docInfo[1].used,
      total: docInfo[1].total,
    },
  ];

  // 차트용 데이터
  const analysisTotal = docInfo[1]?.total || 0;
  const tickAnalysisValues = Array.from(
    { length: analysisTotal + 1 },
    (_, i) => i
  );

  const parsingTotal = docInfo[0]?.total || 0;
  const tickParsingValues = Array.from(
    { length: parsingTotal + 1 },
    (_, i) => i
  );

  const userRoleAvatar = (roles) => {
    let avatarText = null;
    let avatarColor = null;

    if (roles) {
      if (roles.some((role) => role.startsWith("BASIC"))) {
        avatarText = "B";
        avatarColor = grey[500];
      } else if (roles.some((role) => role.startsWith("STANDARD"))) {
        avatarText = "S";
        avatarColor = blue[300];
      } else if (roles.some((role) => role.startsWith("PREMIUM"))) {
        avatarText = "P";
        avatarColor = red[300];
      }
    }

    // Avatar 컴포넌트 생성
    const avatarComponent = (
      <Avatar sx={{ bgcolor: avatarColor, width: 30, height: 30 }}>
        {avatarText}
      </Avatar>
    );

    // ADMIN 역할이 있는 경우 Badge로 감싸기
    return roles && roles.includes("ADMIN") ? (
      <Badge color="success" variant="dot">
        {avatarComponent}
      </Badge>
    ) : (
      avatarComponent
    );
  };

  const userRoleText = (roles) => {
    if (!roles) return "BASIC";

    let roleText = "BASIC"; // 기본값

    // 등급에 따라 텍스트 설정
    if (roles.some((role) => role.startsWith("BASIC"))) {
      roleText = "BASIC";
    } else if (roles.some((role) => role.startsWith("STANDARD"))) {
      roleText = "STANDARD";
    } else if (roles.some((role) => role.startsWith("PREMIUM"))) {
      roleText = "PREMIUM";
    }

    // ADMIN 역할이 포함된 경우
    if (roles.includes("ADMIN")) {
      return `${roleText} 등급 및 관리자 입니다.`;
    }

    return `${roleText} 등급입니다.`;
  };

  return (
    <Grid>
      <ButtonStatusHook />
      <Grid
        item
        md={12}
        sx={{
          position: "relative",
          height: "23vh",
          backgroundImage: "url(/module.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            whitespace: "nowrap",
          }}
        >
          마이페이지
        </Typography>
      </Grid>

      <Container maxWidth="lg">
        <Grid container sx={{ mt: 5, justifyContent: "center" }}>
          <Grid item xs={12}>
            <Paper
              elevation={6}
              sx={{ p: 4, borderRadius: "20px", boxShadow: 3 }}
            >
              <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={12} sm={3}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {userRoleAvatar(userInfo.roles)} &nbsp;{" "}
                    {userInfoArray[0].value} 님
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {userRoleText(userInfo.roles)}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={9}>
                  <Grid container spacing={2}>
                    {userInfoArray
                      .filter((_, index) => index !== 0)
                      .map((info, index) => (
                        <Grid
                          item
                          key={index}
                          xs={2}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            component="div"
                            color="text.secondary"
                            sx={{ mb: 1 }}
                          >
                            {info.label}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{ fontWeight: "500" }}
                          >
                            {info.value}
                          </Typography>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  클라우드 용량
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "150px",
                      justifyContent: "space-around",
                    }}
                  >
                    {storageInfo.map((storage, index) => {
                      const percentage = (storage.used / storage.total) * 100; // 각기 다른 용량 비율 계산
                      const labels = ["도면", "시트", "문서"]; // 각 CircularProgressbar에 표시할 라벨

                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body2" sx={{ mb: 1 }}>
                            {labels[index]}
                          </Typography>
                          <Box sx={{ width: "120px", height: "70px" }}>
                            <CircularProgressbar
                              value={percentage}
                              text={`${Math.round(percentage)}%`}
                              styles={buildStyles({
                                pathColor: "#3e98c7",
                                trailColor: "#d6d6d6",
                              })}
                            />
                          </Box>
                          <Typography variant="body2" sx={{ mt: 7 }}>
                            {`${storage.usedMB} / ${storage.totalMB}`}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Grid>

              <Grid item>
                <Typography
                  variant="h6"
                  sx={{ mt: 5, mb: 1, fontWeight: "bold" }}
                >
                  독소조항 분석 이용 횟수
                </Typography>
                <Box sx={{ height: 100 }}>
                  <ResponsiveBar
                    data={parsingChartData}
                    colors={{ scheme: "accent" }}
                    keys={["used"]}
                    indexBy="category"
                    margin={{ right: 100, bottom: 50, left: 100 }}
                    padding={0.3}
                    axisBottom={{
                      tickSize: 1,
                      tickPadding: 1,
                      tickValues: tickParsingValues,
                    }}
                    tooltip={({ value }) => `${value}회 이용`}
                    layout="horizontal"
                    maxValue={Math.max(
                      ...parsingChartData.map((item) => item.total)
                    )}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ height: 100 }}>
                  <ResponsiveBar
                    data={analysisChartData}
                    colors={{ scheme: "accent" }}
                    keys={["used"]}
                    indexBy="category"
                    margin={{ right: 100, bottom: 50, left: 100 }}
                    padding={0.3}
                    axisBottom={{
                      tickSize: 1,
                      tickPadding: 1,
                      legend: "총 사용 가능 횟수",
                      legendPosition: "middle",
                      legendOffset: 32,
                      tickValues: tickAnalysisValues,
                    }}
                    tooltip={({ value }) => `${value}회 이용`}
                    layout="horizontal"
                    maxValue={Math.max(
                      ...analysisChartData.map((item) => item.total)
                    )}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  sx={{ mt: 5, mb: 1, fontWeight: "bold" }}
                >
                  회원 등급 별 기능 안내
                </Typography>
                <MyPageGradeInfo />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg">
        <Paper
          elevation={6}
          sx={{ borderRadius: "20px", boxShadow: 3, mt: 3, py: 3, px: 2 }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{ ml: "1.5%", fontWeight: "bold" }}
          >
            개인정보 변경
          </Typography>
          <Divider variant="middle" />
          <Grid container spacing={2} sx={{ pt: 2, pl: 2, pr: 3 }}>
            <Grid item xs={12} sm={12} md={6} sx={{ pr: 3 }}>
              <Box
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  ml: "4%",
                }}
              >
                <LocalPhoneIcon sx={{ mr: "1.5%" }} />
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ pr: "1.5%", fontWeight: "bold" }}
                >
                  전화번호 변경
                </Typography>
              </Box>
              <Box
                sx={{
                  mb: 3,
                  ml: "7%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  maxWidth: 500,
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={9}>
                    <TextField
                      type="phone"
                      error={changePhoneError}
                      placeholder=" -(하이픈) 없이 입력"
                      onChange={handleChangePhoneValidation}
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      onClick={onClickChangePhoneButton}
                      disabled={changePhoneButtonStatus}
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      변경
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Dialog
              open={changePhoneAlert}
              onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                  dispatch(setChangePhoneAlert(false));
                }
              }}
            >
              <DialogTitle>{"전화번호 변경 결과"}</DialogTitle>
              <DialogContent>
                <DialogContentText>{changePhoneResult}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    dispatch(setChangePhoneAlert(false));
                  }}
                >
                  닫기
                </Button>
              </DialogActions>
            </Dialog>

            <Grid item xs={12} sm={12} md={6}>
              <ButtonStatusHook />
              <Box
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  ml: "4%",
                }}
              >
                <LockIcon sx={{ mr: "1.5%" }} />
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ pr: "1.5%", fontWeight: "bold" }}
                >
                  비밀번호 변경
                </Typography>
              </Box>
              <Box
                sx={{
                  ml: "7%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  maxWidth: 500,
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={9}>
                    <TextField
                      type="password"
                      error={changeCurrentPwError}
                      placeholder="영문, 숫자, 특수문자 포함 8자 이상"
                      onChange={handleChangeCurrentPwValidation}
                      margin="normal"
                      fullWidth
                      label="현재 비밀번호 입력"
                    />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <TextField
                      type="password"
                      error={changePwError}
                      placeholder="영문, 숫자, 특수문자 포함 8자 이상"
                      onChange={handleChangePwValidation}
                      margin="normal"
                      fullWidth
                      label="변경할 비밀번호 입력"
                    />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <TextField
                      type="password"
                      error={changePwConfirmError}
                      placeholder="영문, 숫자, 특수문자 포함 8자 이상"
                      onChange={handleChangePwConfirm}
                      margin="normal"
                      fullWidth
                      label="변경할 비밀번호 한번 더 입력"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      onClick={() => dispatch(setChangePwAlert(true))}
                      disabled={changePwButtonStatus}
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      변경
                    </Button>

                    <Dialog
                      open={changePwAlert}
                      onClose={(event, reason) => {
                        if (reason !== "backdropClick") {
                          dispatch(setChangePwAlert(false));
                        }
                      }}
                    >
                      <DialogTitle>{"비밀번호 변경 진행"}</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          입력하신 정보로 비밀번호 변경을 진행하시겠습니까?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => dispatch(setChangePwAlert(false))}
                        >
                          {" "}
                          아니오{" "}
                        </Button>
                        <Button onClick={onClickChangePwButton}> 네 </Button>
                      </DialogActions>
                    </Dialog>

                    <Dialog
                      open={completeChangePwAlert}
                      onClose={(event, reason) => {
                        if (reason !== "backdropClick") {
                          dispatch(setCompleteChangePwAlert(false));
                        }
                      }}
                    >
                      <DialogTitle>{"비밀번호 변경 결과"}</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          {completeChangePwResult}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={
                            completeChangePwResult ===
                            "비밀번호가 변경되었습니다. 다시 로그인해주세요."
                              ? () => {
                                  window.location.href = "/";
                                  dispatch(setCompleteChangePwAlert(false));
                                }
                              : () => {
                                  dispatch(setCompleteChangePwAlert(false));
                                }
                          }
                        >
                          {" "}
                          닫기
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {userInfo && userInfo.roles && userInfo.roles.includes("ADMIN") ? (
            <Button
              sx={{ my: 2 }}
              onClick={() => {
                navigate("/module/admin");
                administratorInfo();
                dispatch(setAdministratorPage("info"));
              }}
            >
              관리자 모드
            </Button>
          ) : (
            <Box />
          )}
          <Button
            onClick={() => dispatch(setDeactivateUserAlert(true))}
            sx={{ my: 2, color: "#bdbdbd" }}
          >
            회원탈퇴
          </Button>

          <Dialog
            open={deactivateUserAlert}
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                dispatch(setDeactivateUserAlert(false));
              }
            }}
          >
            <DialogTitle>{"회원 탈퇴 진행"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                정말로 회원탈퇴를 진행하시겠습니까?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => dispatch(setDeactivateUserAlert(false))}>
                {" "}
                아니오{" "}
              </Button>
              <Button color="error" onClick={onClickDeactivateUser}>
                {" "}
                네{" "}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={completeDeactivateUserAlert}
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                dispatch(setCompleteDeactivateUserAlert(false));
              }
            }}
          >
            <DialogTitle>{"회원 탈퇴 결과"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {completeDeactivateUserResult}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={
                  completeDeactivateUserResult ===
                  "저희 서비스를 사용해주셔서 감사합니다."
                    ? () => {
                        window.location.href = "/";
                        dispatch(setCompleteDeactivateUserAlert(false));
                      }
                    : () => {
                        dispatch(setCompleteDeactivateUserAlert(false));
                      }
                }
              >
                {" "}
                닫기
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </Grid>
  );
}
