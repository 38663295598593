import {
  Box,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import CrossInfo from "../components/doc/docInfo/crossInfo.jsx";
import RiskInfo from "../components/doc/docInfo/riskInfo.jsx";
import { useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export default function DocInfo() {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid>
      <Grid
        item
        md={12}
        sx={{
          position: "relative",
          height: "23vh",
          backgroundImage: "url(/module.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            whiteSpace: "nowrap",
          }}
        >
          문서(타공종 교차검증/독소조항 분석) 가이드
        </Typography>
      </Grid>

      <Box sx={{ margin: 7, mb: 0, display: "flex", justifyContent: "center" }}>
        <Container maxWidth="false">
          <Grid container spacing={2}>
            <CrossInfo />
            <RiskInfo />
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ mb: 3 }}>
        <Grid
          item
          md={12}
          sx={{ height: "80vh", display: "flex", flexDirection: "column" }}
        >
          <Typography variant="h5" gutterBottom>
            이용 가이드 영상
          </Typography>
          <Box
            sx={{
              width: "100%",
              typography: "body1",
              height: "100vh",
              justifyContent: "center",
            }}
          >
            <TabContext value={value}>
              <Box
                sx={{
                  width: "100%",
                  bgcolor: "#f5f5f5",
                  borderRadius: "8px 8px 0 0",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="타공종 교차검증"
                    value="1"
                    sx={{
                      minWidth: "50%",
                      fontWeight: "bold",
                      borderRadius: "4px 4px 0 0",
                    }}
                  />
                  <Tab
                    label="독소조항 분석"
                    value="2"
                    sx={{
                      minWidth: "50%",
                      fontWeight: "bold",
                      borderRadius: "4px 4px 0 0",
                    }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                    mb: 2,
                    height: "65vh",
                  }}
                >
                  <iframe
                    width="90%"
                    height="100%" // 고정 높이 설정
                    src="https://www.youtube.com/embed/Hd5BjBsdePA?si=w5oly-hNOUHoBEB3"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe>
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                    mb: 2,
                    height: "65vh",
                  }}
                >
                  <iframe
                    width="90%"
                    height="100%" // 고정 높이 설정
                    src="https://www.youtube.com/embed/vWaH-YPDyqU?si=6oLZuW2tP4d_e1En"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe>
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Container>

      <Container maxWidth="lg">
        <Divider />
        <Typography variant="h5" sx={{ mt: 3 }}>
          참여 기관
        </Typography>
        <Grid container sx={{ mt: 2, mb: 3 }}>
          <Grid item sm={4} md={4}>
            <CardMedia
              component="img"
              image={`${process.env.PUBLIC_URL}/kaist.jpg`}
              alt="description"
              sx={{
                height: "60px",
                objectFit: "contain",
              }}
            />
          </Grid>
          <Grid item sm={4} md={4}>
            <CardMedia
              component="img"
              image={`${process.env.PUBLIC_URL}/wiseitech.png`}
              alt="description"
              sx={{
                height: "60px",
                objectFit: "contain",
                ml: -2,
              }}
            />
          </Grid>
          <Grid item sm={4} md={4}>
            <CardMedia
              component="img"
              image={`${process.env.PUBLIC_URL}/koreauniversity.gif`}
              alt="description"
              sx={{
                height: "60px",
                objectFit: "contain",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
